<script>
import TradingNetworksForm from './TradingNetworksForm'

export default {
  extends: TradingNetworksForm,

  created() {
    this.getTradingNetwork(this.$route.params.id).then(response => {
      this.form = response
    })
  },

  methods: {
    onSubmit() {
      this.updateTradingNetwork(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Торговая сеть успешно изменена', routeName: 'trading-networks' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
